.server-list {
  padding-top: 5px;
  background-color: #202225;
  width: 4.5rem;
  height: 100vh;
  align-items: center;
}

.logo {
  width: 70%;
  border-bottom: 3px solid #373a3f;
}
.server {
  padding: 0;
}

.server img {
  width: 70%;
  border-radius: 100%;
  background-color: #303235;
}
