@font-face {
  font-family: 'Whitney';
  src: url('./fonts/Whitney-Book.woff');
}

body {
  margin: 0;
  font-family: 'Whitney', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #202225;
}
